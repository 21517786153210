import { Icon } from "components/Icon";
import { DebugFAB, EditFAB, PreviewFAB } from "components/interactions/EditorInteractions";
import usePath from "hooks/usePath";
import useUser from "hooks/useUser";
import { t } from "locales";
import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Theme, styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { IFavorite } from "data/contentData/api/user";
import { useFavorites } from "data/contentData/hooks/user.hooks";
interface InteractionsContextType {
  showFavoriteFAB: boolean;
  contentId?: number;
  hasTools: boolean;
  showTools: boolean;
  showToolsFAB: boolean;
  openTools: () => void;
  closeTools: () => void;
  hasNav: boolean;
  showNav: boolean;
  showNavFAB: boolean;
  openNav: () => void;
  closeNav: () => void;
  showPreviewFAB: boolean;
  showDebug: boolean;
  openDebug: () => void;
  closeDebug: () => void;
}
interface IProps {
  contentId?: number;
  hasNav?: boolean;
  hasTools?: boolean;
  favoriteData?: {
    name: string;
    template: string;
    url: string;
  } | undefined;
  showPreviewFAB?: boolean;
  children?: ReactNode;
}
const InteractionsContext = createContext({} as InteractionsContextType);
export function useInteractions() {
  return useContext(InteractionsContext);
}
export function InteractionsProvider({
  contentId,
  hasNav = false,
  hasTools = false,
  favoriteData,
  showPreviewFAB = true,
  children
}: IProps) {
  // Left side navigation
  const [showNav, setShowNav] = useState(false);
  // REVIEW: fixed in v5 (https://github.com/mui-org/material-ui/issues/21745)
  const showNavFAB = useMediaQuery((theme: Theme) => `(max-width:${theme.breakpoints.values.md}px)`) && hasNav;

  // Tools
  const [showTools, setShowTools] = useState(false);
  const showToolsFAB =
  // eslint-disable-next-line no-restricted-syntax
  useMediaQuery((theme: Theme) => theme.breakpoints.down("lg")) && hasTools;

  // Favorite
  const {
    authenticated
  } = useUser();
  const showFavoriteFAB = useScrollTrigger({
    disableHysteresis: true,
    threshold: 160
  }) && !!favoriteData && authenticated;

  // Debug
  const [showDebug, setShowDebug] = useState(false);
  const value = useMemo(() => ({
    contentId,
    showFavoriteFAB,
    hasNav,
    showNav,
    showNavFAB,
    openNav: () => setShowNav(true),
    closeNav: () => setShowNav(false),
    hasTools,
    showTools,
    showToolsFAB,
    openTools: () => setShowTools(true),
    closeTools: () => setShowTools(false),
    showPreviewFAB,
    showDebug,
    openDebug: () => setShowDebug(true),
    closeDebug: () => setShowDebug(false)
  }), [contentId, showFavoriteFAB, hasNav, showNav, showNavFAB, hasTools, showTools, showToolsFAB, showPreviewFAB, showDebug]);
  return <InteractionsContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="InteractionsProvider" data-sentry-source-file="InteractionsContext.tsx">
            {children}
            <Interactions {...value} favorite={favoriteData} data-sentry-element="Interactions" data-sentry-source-file="InteractionsContext.tsx" />
        </InteractionsContext.Provider>;
}
function Interactions(props: InteractionsContextType & {
  favorite?: IFavorite;
}) {
  const {
    openNav,
    showNavFAB,
    showToolsFAB,
    openTools,
    favorite,
    showFavoriteFAB,
    showPreviewFAB
  } = props;
  const url = usePath();
  const {
    toggle,
    favorite: _favorite
  } = useFavorites(url);
  return <FabContainer data-sentry-element="FabContainer" data-sentry-component="Interactions" data-sentry-source-file="InteractionsContext.tsx">
            {showPreviewFAB && <PreviewFAB />}
            <EditFAB data-sentry-element="EditFAB" data-sentry-source-file="InteractionsContext.tsx" />
            <DebugFAB data-sentry-element="DebugFAB" data-sentry-source-file="InteractionsContext.tsx" />
            {showFavoriteFAB ? <Zoom in>
                    <Tooltip placement="right" title={!!_favorite ? t["favorite"]["remove-from-favorites"] : t["favorite"]["add-to-favorites"]}>
                        <Fab onClick={() => toggle(favorite)} size="medium">
                            {_favorite ? <Icon name="favorite-added" /> : <Icon name="favorite-removed" />}
                        </Fab>
                    </Tooltip>
                </Zoom> : null}

            {showToolsFAB ? <Zoom in>
                    <Tooltip placement="right" title={t["show-tools"]}>
                        <Fab onClick={openTools} size="medium">
                            <Icon name="tool" />
                        </Fab>
                    </Tooltip>
                </Zoom> : null}

            {showNavFAB ? <Zoom in>
                    <Tooltip placement="right" title={t["show-navigation"]}>
                        <Fab color="secondary" onClick={openNav} size="medium">
                            <Icon name="navigation" />
                        </Fab>
                    </Tooltip>
                </Zoom> : null}
        </FabContainer>;
}
const FabContainer = styled("div")(({
  theme
}) => ({
  position: "fixed",
  bottom: "1rem",
  right: "1rem",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "8px",
  zIndex: theme.zIndex.fab,
  "@media print": {
    display: "none!important"
  }
}));